<template>
  <div class="primary-colour" style="width:100%;margin-bottom: -30px !important;">
    <div v-if="clubs.length > 0">
      <div style="margin-bottom:20px;">
        existing clubs ({{ $parent.clubLetter }})
      </div>
      <BTable
        id="clubs-table"
        class="text-left clubsTable mt-3 mt-md-4 mb-5"
        style="overflow-y: auto;"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="clubs"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        responsive
        :striped="true"
        hover
        small
        sort-icon-left
        auto-width
      >
        <template v-slot:cell(clubid)="data">
          <div
            class="adminTableRow"
            style="margin-top:12px;"
            :style="{ 'color': data.item.IsDeleted ? '#1FFC35 !important' : '#ffffff !important'}"
          >
            {{ data.item.ClubId }}
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <div
            class="adminTableRow"
            style="margin-top:12px;"
            :style="{ 'color': data.item.IsDeleted ? '#1FFC35 !important' : '#ffffff !important'}"
          >
            {{ data.item.Name }}
          </div>
        </template>
        <template v-slot:cell(runningprizetotal)="data">
          <div
            class="adminTableRow"
            style="margin-top:12px;"
            :style="{ 'color': data.item.IsDeleted ? '#1FFC35 !important' : '#ffffff !important'}"
          >
            £{{ data.item.RunningPrizeTotal.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell(buttons)="data">
          <div
            class="d-flex align-items-center justify-content-center pt-0"
            style="margin-top:2px;"
          >
            <mdb-popover
              ref="popover"
              trigger="click"
              :options="{placement: 'left'}"
              style="cursor:pointer;;"
            >
              <div
                slot="body"
              >
                <p
                  class="d-flex align-items-center m-0 mb-1"
                  style="color:#555 !important;font-size:0.8rem;margin-top:-1px !important;"
                  @click="addEditClub(data.item.ClubId)"
                >
                  <i class="fa fa-edit mr-2" />EDIT
                </p>
                <p
                  class="d-flex align-items-center m-0 mb-1"
                  style="color:#555 !important;font-size:0.8rem;margin-top:5px !important;"
                  @click="getClubRevenues(data.item)"
                >
                  <i class="fa fa-credit-card mr-2" />RECONCILIATION
                </p>
                <p
                  v-if="userData.cid !== data.item.ClubId && data.item.IsDeleted === false"
                  class="d-flex align-items-center m-0 mb-1"
                  style="color:#555 !important;font-size:0.8rem;margin-top:5px !important;margin-bottom:-3px !important;"
                  @click="switchClub(data.item)"
                >
                  <i class="fa fa-cog mr-2" />SWITCH TO THIS CLUB
                </p>
              </div>
              <mdb-btn
                slot="reference"
                style="background-color: none !important;"
                class="px-2 m-0 btn-blank d-flex align-items-center justify-content-ecnter align-self-end"
                :style="{ 'color': data.item.IsDeleted ? '#1FFC35 !important' : '#ffffff !important'}"
              >
                <i class="fa fa-ellipsis-v" />
              </mdb-btn>
            </mdb-popover>
          </div>
        </template>
      </BTable>

      <div style="margin-top:-40px;margin-bottom: 20px;">
        *total revenue since onboarding
      </div>

      <div
        v-if="clubs.length > perPage"
        class="p-1 mt-2"
        style="width:100%; background: #fff; border-radius: 5px;margin-bottom: 30px !important;"
      >
        <b-pagination
          v-show="clubs.length > perPage"
          v-model="currentPage"
          class="ml-auto"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="clubs-table"
          prev-text="<"
          next-text=">"
        />
      </div>
    </div>
    <div
      v-else
      style=" margin-left:0px;margin-top:0px !important;margin-bottom: 30px !important;"
      class="mt-4 primary-colour"
    >
      Sorry, no clubs found.
    </div>
    <mdb-modal
      id="switchClubModal"
      class="black-text"
      :show="switchClubModal"
      @close="$emit('switchClubModal', false)"
    >
      <mdb-modal-header class="modal-custom-header">
        <mdb-modal-title>
          SWITCH CLUB?
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="modal-custom-body">
        <p class="my-4" style="border-top:1px solid white;margin-top:-10px !important;" />
        <p class="my-4" style="text-align:left;color:#1FFC35 !important;">
          Switching will require you to log back in for the relevant changes to take effect. By proceeding you will be automatically logged out.
        </p>
      </mdb-modal-body>
      <mdb-modal-footer class="modal-custom-footer">
        <mdb-btn
          v-if="!loading"
          style="width:120px;margin:0 !important;font-size:0.8rem !important;"
          class="btn primary-btn btn-radius btn-sm btn-md"
          color="primary"
          @click="confirmSwitchClub()"
        >
          PROCEED
        </mdb-btn>
        <mdb-btn
          v-else
          style="width:120px;margin:0 !important;font-size:0.8rem !important;"
          class="btn primary-btn btn-radius btn-sm btn-md"
          color="primary"
        >
          <i class="fa fa-spinner fa-pulse" />
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'

export default {
  name: 'ClubsTable',
  components: {},  
  props: {
    clubs: {
      type: Array,
      default: () => { }
    }
  },
  data () {
    return {
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      perPage: 10,
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      cid: 0,
      clubName: '',
      loading: false,
      switchClubModal: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ]),
    fields () {
      if (this.windowWidth < 577) {
        return [
          { key: 'ClubId', label: 'Id' },
          { key: 'Name', label: 'Name', sortable: true },
          { key: 'RunningPrizeTotal', label: '£' },
          { key: 'Buttons', label: '' }
        ]
      } else {
        return [
          { key: 'ClubId', label: 'Club Id' },
          { key: 'Name', label: 'Club Name', sortable: true },
          { key: 'RunningPrizeTotal', label: 'Revenue*' },
          { key: 'Buttons', label: '' }
        ]
      }
    },
    rows () {
      try {
        return this.clubs.length
      } catch { return 0 }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    async addEditClub (cid) {
      this.$emit('cid', cid)
      this.$nextTick(() => {
        this.$emit('setPage', 'AddEditClub')
      })
    },
    getClubTransactions (cid) {
      this.$emit('cid', cid)
      this.$emit('setPage', 'Transactions')
    },
    getClubRevenues (club) {
      this.$parent.clubName = club.Name
      this.$emit('cid', club.ClubId)
      this.$emit('clubName', club.Name)
      this.$emit('setPage', 'Revenues')
    },
    switchClub (club) {
      this.switchClubModal = true;
      this.clubId = club.ClubId;
    },
    confirmSwitchClub () {
      this.loading = true;
      this.switchClubModal = true;
      try {
        userService.switchClub(this.userData.access_token, this.clubId).then(() => {
          this.$store.commit('storeUserData', { aut: false, lbl: [] })
          this.$store.commit('storeId', 0)
          this.$store.commit('storeHero', 0)
          this.$store.commit('storeSport', 0)
          this.$store.commit('storeColour', '#e1e1e1')
          this.$store.commit('storeNumber', 0)
          this.$store.commit('storeFirstInitial', '')
          this.$store.commit('storeSecondInitial', '')
          this.$store.commit('storeClubUrl', '')
          this.$store.commit('clearBasket')
          this.$store.commit('storeLoginData', true)
          this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
          this.$emit('logout')
          this.$emit('show')
        })
      } catch {
        this.loading = false;
        this.switchClubModal = false;
      }
    }
  }
}
</script>

<style lang="scss">
.pagination button[aria-checked=true] {
  background: var(--pr-color) !important;
}
</style>
<style scoped>
.revenuesTable * {
  font-size: 0.85rem !important;
}
.clubsTable {
  font-size: 0.85rem !important;
}
@media (max-width: 576px) {
  .clubTop {
    margin-top: 11px !important;
  }
}
</style>
