<template>
  <div
    class="primary-colour"
    style="margin-top: 10px;"
  >
    <div
      v-if="dataLoaded"
      class="primary-colour"
      style="margin-bottom:30px;"
    >
      <div class="hideMobile">
        <i
          v-if="showLastMonthChevron"
          class="fa fa-chevron-circle-left rightChevron"
          style="cursor:pointer;"
          title="Last month"
          @click="lastMonth"
        />
        <i
          v-else
          class="fa fa-chevron-circle-left rightChevron"
          style="opacity: 0.5;"
          title="Last month"
        />
        Leaderboard winners for {{ desk_month }} {{ year }} 
        <i
          v-if="showNextMonthChevron"
          class="fa fa-chevron-circle-right rightChevron"
          style="cursor:pointer;"
          title="Next month"
          @click="nextMonth"
        /> 
        <i
          v-else
          class="fa fa-chevron-circle-right rightChevron"
          style="opacity: 0.5;"
          title="Next month"
        /> 
      </div>
      <div class="showMobile">
        <i
          class="fa fa-chevron-circle-left leftChevron"
          style="cursor:pointer;"
          title="Last month"
          @click="lastMonth"
        />
        {{ mob_month }} {{ year }} 
        <i
          v-if="showNextMonthChevron"
          class="fa fa-chevron-circle-right rightChevron"
          style="cursor:pointer;"
          title="Next month"
          @click="nextMonth"
        /> 
        <i
          v-else
          class="fa fa-chevron-circle-right rightChevron"
          style="opacity: 0.5;"
          title="Next month"
        /> 
      </div>
    </div>
    <div v-if="lbl.length > 0">
      <BTable
        id="leaderboard-table"
        class="text-left revenuesTable"
        head-variant="dark"
        :items="lbl"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        responsive
        :striped="true"
        hover
        small
        sort-icon-left
        auto-width
      >
        <template v-slot:cell(clubname)="data">
          <div
            v-if="windowWidth > 576"
            class="adminTableRow"
            style="margin-top:12px;margin-bottom:12px;"
          >
            {{ data.item.ClubName }}
          </div>
          <div
            v-else
            class="adminTableRow"
            style="margin-top:12px;margin-bottom:12px;"
          >
            <span class="primary-colour">Club:</span> {{ data.item.ClubName }}<br>
            <span class="primary-colour">Player:</span> {{ data.item.PlayerName }}<br>
            <span class="primary-colour">Date:</span> {{ data.item.Month }}/{{ data.item.Year }}<br>
            <span class="primary-colour">Prize:</span> £{{ data.item.Prize.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell(playername)="data">
          <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
            {{ data.item.PlayerName }}
          </div>
        </template>
        <template v-slot:cell(month)="data">
          <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
            {{ data.item.MonthName }} {{ data.item.Year }}
          </div>
        </template>
        <template v-slot:cell(prize)="data">
          <div class="adminTableRow" style="margin-top:12px;margin-bottom:12px;">
            £{{ data.item.Prize.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell(ispaid)="data">
          <div class="adminTableRow" style="margin-top:12px;">
            <!-- PAID -->
            <span
              v-if="data.item.DateReconciled"
              class="payoutAdminTableStatus paid"
              :title="`paid by ${data.item.ReconciledBy}`"
              style="cursor:pointer;"
            >
              <i class="fa fa-check" />
              PAID
            </span>
            <!-- DUE -->
            <div
              v-else-if="paymentIsDue(data.item.Month)"
              v-show="data.item.Prize > 0"
              class="payoutAdminTableStatus due"
              :class="{'d-flex align-items-center' : data.item.Prize > 0 }"
            >
              <div
                class="primary-colour"
                style="font-size: 0.8rem !important;cursor: pointer;width:90px;"
                @click="setUserAsPaid(data.item)"
              >
                <span v-if="!settingAsPaid">SET AS PAID?</span>
                <span v-else><i class="fa fa-spin fa-spinner" style="margin-left:20px;" /></span>
              </div>
            </div>
            <!-- ONGOING - NOT PAID BUT DATE OF PAYMENT IS IN THE FUTURE -->
            <span
              v-else
              class="payoutAdminTableStatus ongoing"
            >
              ONGOING
            </span>
            <!-- DUE -->
          </div>
        </template>
      </BTable>
    </div>
    <div
      v-else
      style="margin:auto 0;text-align: center;font-size:2rem;margin-top:40px;"
      class="primary-colour"
    >
      <div
        v-if="dataLoaded"
        style="font-size: 1rem; text-align:left;margin-top:-20px;color:white;margin-bottom:20px;"
      >
        Sorry, there is no leaderboard data to manage for this month, due to either no entries or all leaderboards were just for fun!
      </div>
      <div v-else>
        <i class="fa fa-spin fa-spinner" />
      </div>
    </div>
    <div v-if="dataLoaded">
      <div
        class="primary-colour"
        style="margin-bottom: 30px;margin-left: 5px;"
        :style="{ 'margin-left': lbl.length == 0 ? '0px' : '5px' }"
      >
        Available CSV downloads:
      </div>
      <div class="mr-4 mr-md-5 noRightPadding">
        <mdb-btn
          v-show="!spinningLeaderboardDump"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
          title="Leaderboard data for all clubs."
          @click="getLeaderboardDump()"
        >
          LEADERBOARD
        </mdb-btn>
        <mdb-btn
          v-show="spinningLeaderboardDump"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;opacity:0.5;"
          title="Leaderboard data for all clubs."
          size="sm"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
      <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
        <mdb-btn
          v-show="!spinningLeaderboardWinnersDump"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
          size="sm"
          title="Leaderboard winners for all clubs incl. just for fun!"
          @click="getLeaderboardWinnersDump()"
        >
          LEADERBOARD WINNERS
        </mdb-btn>
        <mdb-btn
          v-show="spinningLeaderboardWinnersDump"
          class="btn primary-btn btn-radius m-0 mobWidth"
          style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;opacity:0.5;"
          title="Leaderboard winners for all clubs incl. just for fun!"
          size="sm"
        >
          <mdb-icon icon="fa fa-pulse fa-spinner" />
        </mdb-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'
import { PAYMENT_IS_DUE } from '@/common/utils'

export default {
  name: '',
  data () {
    return {
      spinningLeaderboardDump: false,
      spinningLeaderboardWinnersDump: false,
      lbl: [],
      currentPage: 1,
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      desk_month: '',
      mob_month: '',
      year: '',
      m: null,
      showNextMonthChevron: false,
      showLastMonthChevron: true,
      settingAsPaid: false,
      dataLoaded : false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'ClubName', label: 'Club' },
          { key: 'PlayerName', label: 'Player' },
          { key: 'Month', label: 'Date', sortable: true },
          { key: 'Prize', label: 'Amount' },
          { key: 'IsPaid', label: 'Status' }
        ]
      } else {
        return [
          { key: 'ClubName', label: 'Club' },
          { key: 'IsPaid', label: 'Status' }
        ]
      }
    },
    perPage () {
      return 12
    },
    rows () {
      return 12
    }
  },
  created () {
    var d = new Date();
    this.m = d.getMonth() + 1;
    if (this.m == 1) { this.desk_month = "January"; this.mob_month = "Jan"; }
    if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
    if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
    if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
    if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
    if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
    if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
    if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
    if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
    if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
    if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
    if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
    this.year = d.getFullYear();
    this.showNextMonthChevron = false
    this.adminLeaderboardReconciliation()
  },
  methods: {
    getLeaderboardDump () {
      this.spinningLeaderboardDump = true
      baseService.getLeaderboardDump(this.userData.access_token).then((res) => {
        this.spinningLeaderboardDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningLeaderboardDump = false
      })
    },
    getLeaderboardWinnersDump () {
      this.spinningLeaderboardWinnersDump = true
      baseService.getLeaderboardWinnersDump(this.userData.access_token).then((res) => {
        this.spinningLeaderboardWinnersDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningLeaderboardWinnersDump = false
      })
    },
    adminLeaderboardReconciliation () {
      this.dataLoaded = false;
      baseService.adminLeaderboardReconciliation(this.m, this.year, this.userData.access_token).then((res) => {
        this.lbl = res.data
        this.settingAsPaid = false;
        this.dataLoaded = true;
      }).catch(() => {
        this.settingAsPaid = false;
        this.dataLoaded = true;
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
    } ,
    paymentIsDue (paymentDate, monthDate) {
      if (monthDate <= paymentDate) {
        return PAYMENT_IS_DUE(monthDate)
      } else {
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth()
        const currentYear = currentDate.getFullYear()
        const checkDate = new Date(paymentDate)
        const paymentMonth = checkDate.getMonth()
        const paymentYear = checkDate.getFullYear()
        if (paymentMonth === currentMonth && paymentYear === currentYear) {
          return false
        } else {
          return PAYMENT_IS_DUE(paymentDate)
        }
      }
    },
    async setUserAsPaid (data) {
      console.log(data)
      //this.settingAsPaid = true;
      //try {
        //await baseService.setUserAsPaid(data.Month, data.Year, data.Prize, data.TotalPrize, data.PlayerId, data.BasketId, this.userData.access_token).then((res) => {
          //if (res.data) {
            //this.adminLeaderboardReconciliation()
            //this.settingAsPaid = false;
          //}
          //else {
            //this.settingAsPaid = false;
            //this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
          //}
      //}).catch(() => {
        //this.settingAsPaid = false;
        //this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      //})
      //} catch (err) {
        //this.settingAsPaid = false;
        //this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      //}
    },
    nextMonth() {
      this.noData = false
      this.m = this.m + 1
      if (this.m == 13) { this.m = 1; this.year++; }
      let date = new Date()
      var month = date.getMonth() + 1
      if (month <= this.m && this.year >= date.getFullYear()) {
        this.showNextMonthChevron = false
      }
      if (this.m >= 10 && this.year >= 2024) {
        this.showLastMonthChevron = true
      }
      if (this.m == 1) { this.desk_month = "January"; this.mob_month = "Jan"; }
      if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
      if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
      if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
      if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
      if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
      if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
      if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
      if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
      if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
      if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
      if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
      this.lbl = []
      this.adminLeaderboardReconciliation()
    },
    lastMonth() {
      this.showNextMonthChevron = true
      this.noData = false
      this.m = this.m - 1;
      if (this.m == 0) { this.m = 12; this.year--; }
      if (this.m <= 10 && this.year <= 2024) {
        this.showLastMonthChevron = false
      }
      if (this.m == 1) { this.desk_month = "January"; this.mob_month = "Jan"; }
      if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
      if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
      if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
      if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
      if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
      if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
      if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
      if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
      if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
      if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
      if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
      this.lbl = []
      this.adminLeaderboardReconciliation()
    }   
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .mobWidth {
    width:100% !important;
 }
 .noRightPadding {
    margin-right: 0px !important;
 }
 .mobLb {
    width:50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
} 
.showMobile {
  display:none;
}
.hideMobile {
  display:block;
}
.payoutAdminTableStatus.paid {
  color: var(--pr-color);
}
.payoutAdminTableStatus.due {
  color: red;
}
.revenuesTable * {
  font-size: 0.85rem !important;
}
@media (max-width:768px) {
  .showMobile {
    display:block;
  }
  .hideMobile {
    display:none;
  }
}
@media (max-width:576px) {
  .showMobile {
    display:block;
  }
  .hideMobile {
    display:none;
  }
}
</style>
