<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <div
      class="p-3 mb-2 d-flex justify-content-center"
      style="font-size: clamp(1rem, 2vw, 2rem);width: 100%; border: solid 2px var(--pr-color);margin-bottom:20px !important;"
    >
      <span>Total Users:</span>
      <span class="text-white font-weight-bold ml-2">{{ totalUsers }}</span>
    </div>
    <div
      class="p-3 mb-2 d-flex justify-content-center"
      style="font-size: clamp(1rem, 2vw, 2rem);width: 100%; border: solid 2px var(--pr-color);margin-bottom:20px !important;"
    >
      <span>Total Heroes:</span>
      <span class="text-white font-weight-bold ml-2">{{ totalHeroes }}</span>
    </div>
    <div
      class="p-3 mb-2 d-flex justify-content-center"
      style="font-size: clamp(1rem, 2vw, 2rem);width: 100%; border: solid 2px var(--pr-color);margin-bottom:20px !important;"
    >
      <span>Total Clubs:</span>
      <span class="text-white font-weight-bold ml-2">{{ totalClubs }}</span>
    </div>
    <div
      class="p-3 mb-2 d-flex justify-content-center"
      style="font-size: clamp(1rem, 2vw, 2rem);width: 100%; border: solid 2px var(--pr-color);margin-bottom:20px !important;"
    >
      <span>Total Charities:</span>
      <span class="text-white font-weight-bold ml-2">{{ totalCharities }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: 'Stats',
  data () {
    return {
      totalUsers: 0,
      totalClubs: 0,
      totalHeroes: 0,
      totalCharities: 0
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  created () {
    this.getStats()
  },
  methods: {
    getStats () {
      baseService.stats(this.userData.access_token).then((res) => {
        this.totalClubs = res.data.Clubs
        this.totalHeroes = res.data.Heroes
        this.totalUsers = res.data.Users
        this.totalCharities = res.data.Charities
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
