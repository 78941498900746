<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <div
      v-for="(type, i) in dataToShow"
      :key="i" 
      class="p-3 mb-2 d-flex justify-content-center"
      style="font-size: clamp(1rem, 2vw, 2rem);width: 100%; border: solid 2px var(--pr-color);margin-bottom:20px !important;"
    >
      <span>{{ type.text }}:</span>
      <span
        v-if="type.amount === 'TBC'"
        class="text-white font-weight-bold ml-2"
      >
        {{ type.amount }}
      </span>
      <span
        v-else
        class="text-white font-weight-bold ml-2"
      >
        £{{ type.amount }}
      </span>
    </div>
    *for current year
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: 'RevenueBreakdown',
  data () {
    return {
      prizePot: 0.00,
      totalRevenue: 0.00,
      clubRevenue: 0.00,
      boostPot: 0.00
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    dataToShow () {
      return [
        { text: 'Total Revenue*', amount: this.totalRevenue },
        { text: 'Club Revenue*', amount: this.clubRevenue },
        { text: 'GRL Revenue*', amount: this.grlRevenue },
        { text: 'Prize Pot*', amount: this.prizePot },
        { text: 'Total Boost Pot*', amount: this.boostPot }
      ]
    }
  },
  created () {
    this.privatePrizePot()
  },
  methods: {
    privatePrizePot () {
      baseService.privatePrizePot(this.userData.access_token).then((res) => {
        this.prizePot = res.data.TotalPrizePot
        this.totalRevenue = res.data.TotalRevenue
        this.clubRevenue = res.data.ClubRevenue
        this.boostPot = res.data.BoostPot
        this.grlRevenue = res.data.GrlRevenue
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
