<template>
  <div class="mt-3 mt-md-4 mb-5">
    <div v-if="transactions.length > 0">
      <BTable
        class="text-left text-center"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="transactions"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        responsive
        :striped="true"
        hover
        small
        sort-icon-left
        auto-width
      >
        <template v-slot:cell(dateadded)="data">
          <div>{{ new Date(data.item.DateAdded).toLocaleDateString([userLanguage]) }}</div>
        </template>
        <template v-slot:cell(maskedpan)="data">
          <div style="margin-top:12px;font-size:0.8rem;">
            {{ data.item.MaskedPan }}
          </div>
          <div class="d-flex d-md-none">
            {{ data.item.MaskedPan.substring(13,17) }}
          </div>
        </template>
        <template v-slot:cell(amount)="data">
          <div>£{{ data.item.Amount.toFixed(2) }}</div>
        </template>
        <template v-slot:cell(transactionreference)="data">
          <div class="text-nowrap">
            {{ data.item.TransactionReference }}
          </div>
        </template>
      </BTable>
      <div v-if="transactions.length > perPage" style="width:100%;">
        <b-pagination
          v-show="transactions.length > perPage"
          v-model="currentPage"
          class="ml-auto mt-4"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="transactions-table"
          prev-text="<"
          next-text=">"
        />
      </div>
    </div>
    <div v-else class="mt-4">
      There are no transaction records to show
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'

export default {
  name: 'ClubTransactions',
  props: {
    clubId: Number
  },
  data () {
    return {
      currentPage: 1,
      transactions: [],
      sortBy: '',
      sortDesc: false,
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'DateAdded', label: 'Transaction Date', sortable: true },
          { key: 'Amount', label: 'Amount Paid', sortable: true },
          { key: 'PaymentTypeDescription', label: 'Payment Type' },
          { key: 'MaskedPan', label: 'Masked Card' },
          { key: 'TransactionReference', label: 'Transaction Ref.' }
        ]
      } else {
        return [
          { key: 'DateAdded', label: 'Date', sortable: true },
          { key: 'Amount', label: 'Paid', sortable: true },
          { key: 'MaskedPan', label: 'Card' },
          { key: 'TransactionReference', label: 'Reference' }
        ]
      }
    },
    perPage () {
      return this.windowHeight > 700 ? 9 : 7
    },
    rows () {
      try {
        return this.transactions.length
      } catch { return 0 }
    }
  },
  mounted () {
    if (this.clubEmail !== undefined) {
      this.getClubTransactions()
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    getClubTransactions () {
      userService.getClubTransactions(this.userData.access_token, this.clubEmail).then((res) => {
        this.transactions = res.data
      })
    }
  }
}
</script>

<style lang="scss">
.pagination button[aria-checked=true] {
  background: var(--pr-color) !important;
}
</style>
