<template>
  <div
    v-if="userData.aut && userData.rle === 'sup'"
    class="text-white"
    style="width:100%;"
  >
    <div class="d-flex align-items-center row m-0">
      <div class="col-12 col-md-6 d-flex align-items-center order-md-1 p-0">
        <Search
          class="ml-auto p-0 flex-grow-1 greenForm"
          @enter="searchPlayer()"
          @search="search = $event"
        />
        <div style="width:fit-content;" class="pr-0 pl-2 text-right">
          <mdb-btn
            :disabled="search.length < 3"
            style="width:120px;margin:0 !important;font-size:0.8rem !important;"
            class="btn primary-btn btn-radius btn-sm btn-md"
            @click="searchPlayer()"
          >
            SEARCH
          </mdb-btn>
        </div>
      </div>
      <div style="text-align: left;font-size: 0.9rem;width:100%;" class="col-12 col-md-6 mt-3 mt-md-0">
        <span v-if="playersPage == ''">Search for a player by email, firstname or surname to perform admin functions.</span>
      </div>
    </div>

    <PlayersTable
      v-if="playersPage == 'search'"
      :players="players"
      style="margin-top: 20px;"
      @refreshPlayersTable="searchPlayer()"
      @playerTransactionRecords="playerTransactionRecords"
      @editPlayer="editPlayer"
    />

    <PlayersTransactionRecords
      v-if="playersPage == 'transaction-records'"
      :email="email"
    />

    <EditPlayer
      v-if="playersPage == 'edit-player'"
      :email="email"
    />

    <mdb-btn
      v-if="playersPage != '' && playersPage != 'search'"
      class="btn primary-btn btn-right btn-radius btn-sm btn-md m-0 mr-2"
      style="width:120px;margin-right:10px !important;font-size:0.8rem !important;"
      @click="back()"
    >
      BACK
    </mdb-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Search from '@/components/search.vue'
import adminService from '@/api-services/admin.service'
import PlayersTable from './playersTable.vue'
import PlayersTransactionRecords from './playerTransactionRecords.vue'
import EditPlayer from './editPlayerDetails.vue'

export default {
  name: 'Players',
  components: { Search, PlayersTable, PlayersTransactionRecords, EditPlayer },
  data () {
    return {
      search: '',
      players: [],
      playersPage: '',
      email: ''
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  created () {
  },
  methods: {
    back () {
      this.playersPage = ''
      this.searchPlayer()
    },
    async searchPlayer () {
      this.$emit('loading', true)
      this.players = []
      try {
        const res = await adminService.searchPlayers(this.search, this.userData.access_token)
        this.players = res.data
        this.playersPage = 'search'
      } catch (err) {
        //?
      }
      setTimeout(() => {
        this.$emit('loading', false)
      }, 500)
    },
    playerTransactionRecords ({ email }) {
      this.email = email
      this.playersPage = 'transaction-records'
    },
    editPlayer ({ email }) {
      this.email = email
      this.playersPage = 'edit-player'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
