import { render, staticRenderFns } from "./addEditClubDetails.vue?vue&type=template&id=4edb0db0&scoped=true&"
import script from "./addEditClubDetails.vue?vue&type=script&lang=js&"
export * from "./addEditClubDetails.vue?vue&type=script&lang=js&"
import style0 from "./addEditClubDetails.vue?vue&type=style&index=0&id=4edb0db0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4edb0db0",
  null
  
)

export default component.exports