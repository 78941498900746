<template>
  <div class="mt-3 mt-md-4 mb-5">
    <div v-if="transactions.length > 0">
      <BTable
        id="transactions-table"
        class="text-left playerTransactionsTable"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="transactions"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        responsive
        :striped="true"
        hover
        small
        sort-icon-left
        auto-width
      >
        <template v-slot:cell(dateadded)="data">
          <div style="margin-top:12px;margin-bottom: 12px;">
            {{ new Date(data.item.DateAdded).toLocaleDateString([userLanguage]) }} {{ new Date(data.item.DateAdded).toLocaleTimeString([userLanguage]).substring(0, 5) }}
          </div>
        </template>
        <template v-slot:cell(maskedpan)="data">
          <div style="margin-top:6px;margin-bottom: 6px;width:50px;background-color:white;border-radius: 5px;padding-left:5px;padding-right:5px;float:left;">
            <img
              v-if="windowWidth > 576"
              :src="require('@/assets/' + data.item.PaymentTypeDescription + '.png')"
              style="width:40px;height:30px;"
            >
          </div>
          <div v-if="windowWidth > 576" style="margin-left:10px;float:left;margin-top:12px;">
            {{ data.item.MaskedPan }}
          </div>
          <div v-else style="margin-top:12px;margin-bottom: 12px;">
            {{ data.item.MaskedPan }}
          </div>
        </template>
        <template v-slot:cell(amount)="data">
          <div style="margin-top:12px;margin-bottom: 12px;">
            £{{ data.item.Amount.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell(transactionreference)="data">
          <div style="margin-top:12px;margin-bottom: 12px;" class="text-nowrap">
            {{ data.item.TransactionReference }}
          </div>
        </template>
      </BTable>
      <div v-if="transactions.length > perPage" style="width:100%;">
        <b-pagination
          v-show="transactions.length > perPage"
          v-model="currentPage"
          class="ml-auto mt-4"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="transactions-table"
          prev-text="<"
          next-text=">"
        />
      </div>
    </div>
    <div v-else class="marginMobile">
      There are no transaction records to show
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'

export default {
  name: 'PlayerTransactions',
  props: {
    email: {
      type: [String],
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      currentPage: 1,
      transactions: [],
      sortBy: '',
      sortDesc: false,
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'DateAdded', label: 'Transaction Date', sortable: true },
          { key: 'Amount', label: 'Amount Paid', sortable: true },
          { key: 'MaskedPan', label: 'Card Details' },
          { key: 'TransactionReference', label: 'Reference' }
        ]
      } else {
        return [
          { key: 'DateAdded', label: 'Date', sortable: true, tdClass: 'col30' },
          { key: 'Amount', label: '£', sortable: true, tdClass: 'col20' },
          { key: 'MaskedPan', label: 'Card', tdClass: 'col20' },
          { key: 'TransactionReference', label: 'Reference', tdClass: 'col30' }
        ]
      }
    },
    perPage () {
      return this.windowHeight > 700 ? 8 : 6
    },
    rows () {
      try {
        return this.transactions.length
      } catch { return 0 }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  created () {
    this.getPlayerTransactions()
  },
  methods: {
    getPlayerTransactions () {
      userService.getPlayerTransactions(this.userData.access_token, this.email).then((res) => {
        this.transactions = res.data
      })
    },
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    }
  }
}
</script>

<style lang="scss">
.pagination button[aria-checked=true] {
  background: var(--pr-color) !important;
}
.playerTransactionsTable * {
  font-size: 0.85rem !important;
  color: var(--lt-color);
}
.marginMobile {
  margin-top: -3.5rem !important;
}
@media (max-width: 576px) {
  .marginMobile {
    margin-top: 1.5rem !important;
  }
}
</style>
