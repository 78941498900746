<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <div class="d-flex align-items-center row m-0">
      <div style="text-align: left;font-size: 0.9rem;width:100%;margin-bottom: 20px;">
        Select which report you would like to download into a CSV file.
      </div>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding">
      <mdb-btn
        v-show="!spinningClubRevenueSummaryDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Club revenue summary data incl. where applicable boost figures."
        @click="clubRevenueSummaryDump()"
      >
        MONTHLY REVENUE
      </mdb-btn>
      <mdb-btn
        v-show="spinningClubRevenueSummaryDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;opacity:0.5;"
        title="Club revenue summary data incl. where applicable boost figures."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningClubEntriesSummaryDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Monthly entry summary data per club."
        @click="clubEntriesSummaryDump()"
      >
        MONTHLY ENTRIES
      </mdb-btn>
      <mdb-btn
        v-show="spinningClubEntriesSummaryDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;opacity:0.5;"
        title="Monthly entry summary data per club."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningPlayerDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Player transactions."
        @click="playerTransactionsDump()"
      >
        PLAYER TRANSACTIONS
      </mdb-btn>
      <mdb-btn
        v-show="spinningPlayerDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Player transactions."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningDrawsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Draws data."
        @click="drawsDump()"
      >
        DRAWS
      </mdb-btn>
      <mdb-btn
        v-show="spinningDrawsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Draws data."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningTicketDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Tickets purchased."
        @click="ticketTransactionsDump()"
      >
        TICKET TRANSACTIONS
      </mdb-btn>
      <mdb-btn
        v-show="spinningTicketDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Tickets purchased."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningRecurDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Recurring payments data."
        @click="recurringPaymentsDump()"
      >
        RECURRING PAYMENTS
      </mdb-btn>
      <mdb-btn
        v-show="spinningRecurDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Recurring payments data."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningFailedDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Failed transaction data."
        @click="failedPaymentsDump()"
      >
        FAILED TRANSACTIONS
      </mdb-btn>
      <mdb-btn
        v-show="spinningFailedDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Failed transaction data."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningClubsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Download of all club data."
        @click="clubDump()"
      >
        CLUBS
      </mdb-btn>
      <mdb-btn
        v-show="spinningClubsDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Download of all club data."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningClubRevenuesDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Download of all reconciled club data."
        @click="clubRevenuesDump()"
      >
        RECONCILIATION
      </mdb-btn>
      <mdb-btn
        v-show="spinningClubRevenuesDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Download of all reconciled club data."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    <div class="mr-4 mr-md-5 noRightPadding" style="margin-top:25px;">
      <mdb-btn
        v-show="!spinningUserDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        title="Download data of all registered players on the platform."
        @click="signUpUsersDump()"
      >
        REGISTRATIONS
      </mdb-btn>
      <mdb-btn
        v-show="spinningUserDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        title="Download data of all registered players on the platform."
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
    
    <!--
    <div
      v-if="userData.uid === 'c16892e8-89fb-4353-a793-3ac84675eed6'"
      style="margin-top:25px;"
      class="mr-4 mr-md-5 noRightPadding"
    >
      <mdb-btn
        v-show="!spinningLb"
        class="btn secondary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="setLeaderBoard();"
      >
        SET LEADERBOARD
      </mdb-btn>
      <mdb-btn
        v-show="spinningLb"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
      <mdb-btn
        v-show="!spinningFakeDrawDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="fakedraw();"
      >
        SIMULATE DRAW
      </mdb-btn>
      <mdb-btn
        v-show="spinningFakeDrawDump"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <mdb-btn
        v-show="spinningExpImage"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <br><br>
      <mdb-btn
        v-show="spinningLb"
        class="btn primary-btn btn-radius m-0 mobWidth"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <br><br>
      <mdb-btn
        class="btn primary-btn btn-radius m-0"
        style="width:200px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        size="sm"
        @click="recurringpayments_REMOVED();"
      >
        RECURRING PAYMENTS
      </mdb-btn>
      @click="retryrecurringpayments()"
    -->
    <div v-if="userData.uid === 'c16892e8-89fb-4353-a793-3ac84675eed6'" style="color:white;margin-top:30px;margin-left: 5px;display:none;">
      <div
        style="margin-bottom:20px;cursor: pointer;"
      >
        > Retry Recurring Payments?
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: '',
  data () {
    return {
      spinningPlayerDump: false,
      spinningDrawsDump: false,
      spinningTicketDump: false,
      spinningRecurDump: false,
      spinningClubsDump: false,
      spinningClubRevenuesDump: false,
      spinningUserDump: false,
      spinningFakeDrawDump: false,
      spinningFakeHeroImage: false,
      spinningExImage: false,
      spinningExpImage: false,
      spinningClubRevenueSummaryDump: false,
      spinningClubEntriesSummaryDump: false,
      spinningFailedDump: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  methods: {
    
    //recurringpayments () {
    //  baseService.recurringpayments(this.userData.access_token).then(() => { 
    //    this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'PROCESS', errorMessage: 'Recurring payments task processing...' })
    //  })
    //},
    //retryrecurringpayments () {
      //baseService.retryrecurringpayments(this.userData.access_token).then(() => { 
        //this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'PROCESS', errorMessage: 'Retry of recurring payments task processing...' })
      //})
    //},
    //no longer required or redundant test code
    //fakedraw () {
    //  this.spinningFakeDrawDump = true
    //  baseService.fakedraw(this.userData.access_token).then((res) => { 
    //    this.spinningFakeDrawDump = false
    //    if (res.data) {
    //      window.location.assign(res.data)
    //    }
    //  }).catch(() => {
        //    this.spinningFakeDrawDump = false
    //  })
    //},
    //setLeaderBoard () {
      //this.spinningLb = true
      //baseService.setLeaderBoard(this.userData.access_token).then(() => { 
        //this.spinningLb = false
      //}).catch(() => {
        //this.spinningLb = false
      //})
    //},
    failedPaymentsDump () {
      this.spinningFailedDump = true
      baseService.failedPaymentsDump(this.userData.access_token).then((res) => {
        this.spinningFailedDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningFailedDump = false
      })
    },
    playerTransactionsDump () {
      this.spinningPlayerDump = true
      baseService.playerTransactionsDump(this.userData.access_token).then((res) => {
        this.spinningPlayerDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningPlayerDump = false
      })
    },
    drawsDump () {
      this.spinningDrawsDump = true
      baseService.drawsDump(this.userData.access_token).then((res) => {
        this.spinningDrawsDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningDrawsDump = false
      })
    },
    ticketTransactionsDump () {
      this.spinningTicketDump = true
      baseService.ticketTransactionsDump(this.userData.access_token).then((res) => {
        this.spinningTicketDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningTicketDump = false
      })
    },
    recurringPaymentsDump () {
      this.spinningRecurDump = true
      baseService.recurringPaymentsDump(this.userData.access_token).then((res) => {
        this.spinningRecurDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningRecurDump = false
      })
    },
    clubDump () {
      this.spinningClubsDump = true
      baseService.clubDump(this.userData.access_token).then((res) => {
        this.spinningClubsDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningClubsDump = false
      })
    },
    clubRevenuesDump () {
      this.spinningClubRevenuesDump = true
      baseService.clubRevenuesDump(this.userData.access_token).then((res) => {
        this.spinningClubRevenuesDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningClubRevenuesDump = false
      })
    },
    signUpUsersDump () {
      this.spinningUserDump = true
      baseService.signUpUsersDump(this.userData.access_token).then((res) => {
        this.spinningUserDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningUserDump = false
      })
    },
    clubRevenueSummaryDump () {
      this.spinningClubRevenueSummaryDump = true
      baseService.clubRevenueSummaryDump(this.userData.access_token).then((res) => {
        this.spinningClubRevenueSummaryDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningClubRevenueSummaryDump = false
      })
    },
    clubEntriesSummaryDump () {
      this.spinningClubEntriesSummaryDump = true
      baseService.clubEntriesSummaryDump(this.userData.access_token).then((res) => {
        this.spinningClubEntriesSummaryDump = false
        if (res.data) {
          window.location.assign(res.data)
        }
      }).catch(() => {
        this.spinningClubEntriesSummaryDump = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .mobWidth {
    width:100% !important;
 }
 .noRightPadding {
    margin-right: 0px !important;
 }
} 
</style>
