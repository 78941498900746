<template>
  <div
    class="primary-colour align-items-center"
    style="width:100%;"
  >
    <div
      class="col-12 d-flex align-items-center flex-wrap text-center m-0 marg-left"
    >
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('ABC')"
      >
        <div
          :class="clubNumbers.ABC === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          ABC
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('DEF')"
      >
        <div
          :class="clubNumbers.DEF === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          DEF
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('GHI')"
      >
        <div
          :class="clubNumbers.GHI === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          GHI
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('JKL')"
      >
        <div
          :class="clubNumbers.JKL === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          JKL
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('MNO')"
      >
        <div
          :class="clubNumbers.MNO === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          MNO
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('PQR')"
      >
        <div
          :class="clubNumbers.PQR === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          PQR
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('STU')"
      >
        <div
          :class="clubNumbers.STU === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          STU
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('VWX')"
      >
        <div
          :class="clubNumbers.VWX === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          VWX
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('YZ')"
      >
        <div
          :class="clubNumbers.YZ === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          YZ
        </div>
      </div>
      <div
        class="cp"
        style="width:60px;line-height:30px;font-size:1rem;"
        @click="getClubsByLetters('NUM')"
      >
        <div
          :class="clubNumbers.NUM === true ? 'selectedOption' : 'nonSelectedOption'"
          class="initialOption m-1 m-md-2"
        >
          0-9
        </div>
      </div>
    </div>
    
    <div class="d-flex align-items-center row m-0">
      <ClubsTable
        v-if="loaded"
        v-show="page === 'ClubsTable'"
        style="margin-top: 20px;"
        :clubs="clubs"
        @cid="cid = $event"
        @setPage="page = $event"
      />

      <ClubsTransactionRecords
        v-if="page === 'Transactions'"
        :club-id="cid"
        :club-name="clubName"
        :club-letters="clubLetter"
        style="margin-top: 20px;"
        @clubName="clubName = $event"
        @cid="cid = $event"
        @setPage="page = $event"
      />

      <ClubsRevenues
        v-if="page === 'Revenues'"
        :club-id="cid"
        :club-name="clubName"
        :club-letter="clubLetter"
        style="margin-top: 20px;"
        @clubName="clubName = $event"
        @clubLetter="clubLetter = $event"
        @cid="cid = $event"
        @setPage="page = $event"
      />

      <AddEditClub
        v-if="page === 'AddEditClub'"
        :cid="cid"
        @cid="cid = $event"
        @setPage="page = $event"
      />
    </div>

    <div
      v-if="loaded && page === 'ClubsTable'"
      style="margin-top:25px;"
    >
      <mdb-btn
        style="width:120px;margin:0 !important;font-size:0.8rem !important;"
        class="btn primary-btn btn-radius btn-sm btn-md"
        @click="addClub()"
      >
        ADD CLUB
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'
import ClubsTable from './clubsTable.vue'
import ClubsTransactionRecords from './clubTransactionRecords.vue'
import AddEditClub from './addEditClubDetails.vue'
import ClubsRevenues from './clubRevenues.vue'

export default {
  name: 'Clubs',
  components: { ClubsTable, ClubsTransactionRecords, AddEditClub, ClubsRevenues },
  props: {
    showTransactions: {
      type: [Boolean],
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      search: '',
      clubs: [],
      clubNumbers: [],
      clubLetter: 'ABC',
      options: ['ABC', 'DEF', 'GHI', 'JKL', 'MNO', 'PQR', 'STU', 'VWX', 'YZ', '0-9'],
      loaded: false,
      cid: 0,
      page: 'ClubsTable',
      clubName: ''
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    searchFromQuery () {
      return this.$route.query.search
    }
  },
  watch: {
    searchFromQuery () {
      if (this.searchFromQuery !== undefined) {
        this.search = this.searchFromQuery
        this.searchClubs()
      }
    }
  },
  created () {
    this.getClubNumbers()
  },
  methods: {
    addClub () {
      this.cid = 0
      this.page = 'AddEditClub'
    },
    async getClubNumbers () {
      try {
        const res = await adminService.getClubNumbers(this.userData.access_token)
        this.clubNumbers = res.data
        if (res.data) {
          this.getClubsByLetters('ABC')
        }
      } catch (err) {
        //?
      }
    },
    async getClubsByLetters (letters) {
      try {
        this.clubLetter = letters
        const res = await adminService.getClubsByLetters(letters, this.userData.access_token)
        this.clubs = res.data !== null ? res.data : []
        this.loaded = true
        this.page = 'ClubsTable'
      } catch (err) {
        this.loaded = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.marg-left {
  margin-left: -23px !important;
}
@media (min-width: 577px) {
  .mobileWidthSpan {
    height: 420px;
  }
}
@media (max-width: 576px) {
    .marg-left {
      margin-left: -19px !important;
    }
  }
</style>
